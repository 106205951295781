var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-table-simple',{staticClass:"mt-3"},[_c('b-thead',[_c('b-tr',[_c('b-th',[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"mx-auto align-self-center"},[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "mesh.capacity", true, "Capacidad" ).toUpperCase())+" ")]),_c('div',[(_vm.allows_crud && _vm.matter)?_c('button-edit',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.top.noninteractive",value:(
                  `Editar ${_vm.$getVisibleNames(
                    'mesh.capacity',
                    true,
                    'Capacidades'
                  )}`
                ),expression:"\n                  `Editar ${$getVisibleNames(\n                    'mesh.capacity',\n                    true,\n                    'Capacidades'\n                  )}`\n                ",modifiers:{"v-secondary":true,"top":true,"noninteractive":true}}],staticClass:"btn-edit-capacity",on:{"click":function($event){return _vm.$bvModal.show(`modal-select-capacity`)}}}):_vm._e()],1)])])],1)],1),_c('b-tbody',[_c('b-tr',[_c('b-td',{staticClass:"pt-2"},[(_vm.capacitiesMatter.length > 0)?_c('div',_vm._l((_vm.capacitiesMatter),function(capacity){return _c('div',{key:capacity.id,staticClass:"text-left pl-4"},[_c('div',[_c('p',[_vm._v(_vm._s(capacity.name))])])])}),0):_c('div',[_vm._v("No hay ninguna capacidad asociada para mostrar.")])])],1)],1)],1),_c('b-modal',{attrs:{"id":`modal-select-capacity`,"hide-footer":"","title":`Seleccionar ${_vm.$getVisibleNames(
      'mesh.capacity',
      true,
      'Capacidad'
    )}`,"size":"lg"}},[(_vm.matter)?_c('CapacityTable',{attrs:{"matter":_vm.matter,"competence_selected_ids":_vm.matter.competences,"matrix2_competences":_vm.matrix2_competences},on:{"close_modal":function($event){return _vm.$bvModal.hide(`modal-select-capacity`)}}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }