<template>
  <div>
    <b-table-simple class="mt-3">
      <b-thead>
        <b-tr>
          <b-th>
            <div class="d-flex">
              <div class="mx-auto align-self-center">
                {{
                  $getVisibleNames(
                    "mesh.capacity",
                    true,
                    "Capacidad"
                  ).toUpperCase()
                }}
              </div>
              <div>
                <button-edit
                  v-if="allows_crud && matter"
                  class="btn-edit-capacity"
                  v-b-tooltip.v-secondary.top.noninteractive="
                    `Editar ${$getVisibleNames(
                      'mesh.capacity',
                      true,
                      'Capacidades'
                    )}`
                  "
                  @click="$bvModal.show(`modal-select-capacity`)"
                ></button-edit>
              </div>
            </div>
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr>
          <b-td class="pt-2">
            <div v-if="capacitiesMatter.length > 0">
              <div
                v-for="capacity in capacitiesMatter"
                :key="capacity.id"
                class="text-left pl-4"
              >
                <div>
                  <p>{{ capacity.name }}</p>
                </div>
              </div>
            </div>
            <div v-else>No hay ninguna capacidad asociada para mostrar.</div>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <b-modal
      :id="`modal-select-capacity`"
      hide-footer
      :title="`Seleccionar ${$getVisibleNames(
        'mesh.capacity',
        true,
        'Capacidad'
      )}`"
      size="lg"
    >
      <CapacityTable
        v-if="matter"
        :matter="matter"
        :competence_selected_ids="matter.competences"
        :matrix2_competences="matrix2_competences"
        @close_modal="$bvModal.hide(`modal-select-capacity`)"
      ></CapacityTable>
    </b-modal>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "MatterCapacities",
  components: {
    CapacityTable: () =>
      import("@/components/mesh/CurricularResources/Competence/CapacityTable"),
  },
  props: {
    matrix2_competences: {
      type: Array,
    },
    egress_profile_matter_id: {
      type: Number,
      required: true,
    },
    allows_crud: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      capacities: names.CAPACITIES,
      egress_profiles_matters: names.EGRESS_PROFILES_MATTERS,
    }),
    matter() {
      return this.egress_profiles_matters.find(
        (x) => x.id == this.egress_profile_matter_id
      );
    },
    egressProfileCapacitiesIds() {
      let capacities = [];
      this.matrix2_competences.forEach((element) => {
        if (this.matter.competences.includes(element.id))
          capacities = capacities.concat(element.capacities);
      });
      return [...new Set(capacities)];
    },
    capacitiesMatter() {
      return this.capacities.filter(
        (x) =>
          this.egressProfileCapacitiesIds.includes(x.id) &&
          this.matter.capacities.includes(x.id)
      );
    },
  },
  methods: {},
  created() {},
};
</script>

<style scoped>
table td {
  border: 1px solid black;
  text-align: center;
  vertical-align: middle;
  font-size: var(--secondary-font-size);
  padding: 2px 2px;
}
table th {
  vertical-align: middle;
  border: 1px solid black;
  color: black !important;
  background-color: var(--primary-color) !important;
  font-size: var(--primary-font-size);
  padding: 2px 2px;
}
.btn-edit-capacity {
  margin-left: auto;
  margin-right: 0;
}
</style>